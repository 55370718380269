import React from 'react';

import './Main.css'

function Main() {

  
    const logout = e =>{
        localStorage.removeItem('budgetTrackerUserid')
        window.location.href="/"
    }

    return (<>

        <div className="main-content">
        <div className="homebg1 animate__animated animate__fadeIn" id="homebg_id">
   
          {/* <img src="/image/social-media-3846597_1280.png" className="bgimage" alt=""/> */}
          <div className="homebg2-content animate__animated animate__fadeIn">
            <div className="container-fluid ">
           <div className="row">
             <div className="col-12">
               <center><img src="/image/Group 3.png" className="index_logo" alt=""/></center>
             </div>
               <div className="col-12">
                   <p>Connect through Words, Share through Blogs</p> 

               </div>
              
           </div>
       </div>
   </div>

</div>
        </div>
        <div className="main-content-features">
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-12 text-center">
                        {/* <h1>Features</h1> */}
                        <br/>
                    </div>
                    {/* <div className="col-md-6">
                        <img src="/image/undraw_setup_analytics_re_foim.svg" alt=""/>
                    </div>
                    <div className="col-md-6">
                    <h2>Monitor </h2> 
                        <p>Easily able to keep track of your monthly expenses as well as your savings in a convenient and effortless manner</p>
                    </div>
                    <div className="col-md-12">
                        <hr/>
                    </div>
                     <div className="col-md-6">
                     <h2>Statement</h2> 
                        <p>Quickly add your  monthly financial records or transactions  with ease</p>
                     
                    </div>
                    <div className="col-md-6">
                    <img src="/image/undraw_credit_card_re_blml.svg" alt=""/>
                    </div> */}
                  
                    <div className="col-md-12">
                        <br/><br/><br/>
                       <h6 style={{color:'black'}}><center>&copy; BlogSpot {new Date().getFullYear()}|| <a href="https://mylnk.co.in/priyonuj" target="_blank" rel="noreferrer" style={{color:'black',textDecoration:'none'}}>priyonujdey</a></center></h6>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Main