import React from 'react';
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import {initializeApp} from 'firebase/app';
import Config from '../Firebase/Config'
import {
    getDatabase,
    ref,
    set,
    update,
    push,
    child,
    remove
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);

const Myblog = () => {
    var [previewdata] = useObjectVal(ref(db, localStorage.getItem('blogspotUser') + '/Profile/name'));
    return (
        <label>
            {previewdata
                ? previewdata
                : ''}
        </label>

    );
};

export default Myblog;
