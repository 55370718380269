import {getAuth,signInWithPopup, OAuthProvider,onAuthStateChanged } from "firebase/auth";
// import jwt_decode from "jwt-decode";
// import createUser from '../User/createUser';
import createUser from './createUser';
import graphUrl from './graphUrl';
import photoUrl from './photoUrl';
const auth = getAuth();
const provider = new OAuthProvider('microsoft.com');
function stringToHash(string) {
    var hash = 0;
    if (string.length == 0) 
        return hash;
    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}

const Microsoft = () => {
    return new Promise(async(resolve, reject) => {
        signInWithPopup(auth, provider).then(async(result) => {
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                  const uid = user.uid;
                  await graphUrl(accessToken).then(async(response) => {
                    await createUser(uid, response.displayName, response.mail).then((result) => {
                        resolve(result);
                    })
                    .catch((error) =>{
                        reject(error)
                    })
                }).catch((error) => {
                    reject(error);
                })
                } else {
                 
                }
              });
           
            // const idToken = credential.idToken;   await createUser(user.uid,
            // user.displayName, idToken.email, 'google', user.photoURL).then((result) => {
            //   console.log(result) }) .catch((error) =>{     console.log(error) })
        }).catch((error) => {
            console.log(error);
            // Handle error.
        });

    })
}

export default Microsoft