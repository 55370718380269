import React from 'react';
import './Content.css'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import {initializeApp} from 'firebase/app';
import Config from '../Firebase/Config'
import parse from 'html-react-parser';
import {useParams, useLocation} from 'react-router-dom';
import Loading from '../Loading/Content/Loading';
import {
    getDatabase,
    ref,
    set,
    update,
    push,
    child,
    remove,
    get
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());
let val = ''
var temp = 0

const Myblog = () => {
    const location = useParams();
    var [previewdata,
        loading] = useObjectVal(ref(db, location.userid + '/Blog/' + location.blogid));
        console.log(previewdata ? previewdata.visibility: '')
    // const [content,     setContent] = React.useState('')
    if (!loading) {

        setTimeout(() => {
            document
                .getElementById('content-loading')
                .style
                .display = 'none';
            document
                .getElementById('contentBody')
                .style
                .display = 'block';
        }, 1000);
        if (previewdata.authorid === localStorage.getItem('blogspotUser')) {
            previewdata
                .Blog
                .content
                .map((data, index) => {
                    if (temp == index) 
                        val += data;
                    temp++
                })
        } else if (previewdata.visibility) 
            previewdata.Blog.content.map((data, index) => {
                if (temp == index) 
                    val += data;
                temp++
            })
        

        }
    const cont = async e => {}
    React.useEffect(() => {
        if (!localStorage.getItem('blogspotUser') || localStorage.getItem('blogspotUser') !== location.userid) {
            get(child(dbRef, '/BlogList/' + location.blogid + '/Views')).then((snapshot) => {
                var count = 0;
                if (snapshot.exists()) 
                    count = snapshot.val().view_count;
                
                update(ref(db, '/BlogList/' + location.blogid + '/Views'), {
                    view_count: count + 1
                });
            })
        }
    }, [])
    return (
        <div>
            <div id="content-loading" className='animate__animated animate__fadeIn'>
                <Loading/>
            </div>
            <div className='content animate__animated animate__fadeIn' id="contentBody">
                {/* {localStorage.getItem('blogspotUser') && localStorage.getItem('blogspotUser') === location.userid
                    ? <a href="/Blog">
                            <span className="material-icons backIcon">keyboard_return</span>
                        </a>
                    : <a></a>
} */}

                <h1 id="contentTitle">{previewdata
                        ? previewdata.Title
                        : ''}</h1>
                <p id="Aname">
                    <label>
                        {/* <span class="material-icons">
                        history_edu
                    </span> */}
                        {previewdata
                            ? previewdata.Blog.Author
                            : ''}</label>
                    <label>
                        {/* <span class="material-icons">
                        today
                    </span> */}
                        {previewdata
                            ? previewdata.Blog.Time
                            : ''}</label>
                </p>
                {previewdata ? !previewdata.visibility && previewdata.authorid !== localStorage.getItem('blogspotUser')
                    ? <p className='privateIcon'>
                            <span class="material-icons ">
                                lock
                            </span>
                            <br/>Private Content
                        </p>
                    : "" : ""}
                <div className="contentThought">
                <span className="contString">
                    {parse(val)}</span>
                </div>
                
            </div>
        </div>
    );
};

export default Myblog;
