import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import './main-modal.css'
import Email from '../Provider/email'
import Google from '../Provider/Google'
import Microsoft from '../Provider/Microsoft'
var customStyles = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '40em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '50%'
            : '30%',
        maxWidth: '60rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};
const customStyles_expense = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '40em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '100%'
            : '50%',
        maxWidth: '60rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};

function Modal_module(props) {

    const [folderName,
        setFolderName] = React.useState('');

    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };
    const [switchButton,
        setSwitchButton] = React.useState(true)
    const submit = async(e) => {
        localStorage.setItem('emailForSignIn', document.getElementById('email1').value.toString().trim())
        await Email(e).then(data => {
            console.log(data)
            //  window.location = '/Dashboard'
        }).catch(err => {
            console.log(err)
            // NotificationManager.error('Error:'+  err.split('/')[1]);
        })
    }
    const handleClickGoogle = async() => {
        await Google().then(data => {
            localStorage.setItem('blogspotUser', data)
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }
    const handleClickMicrosoft = async() => {
        await Microsoft().then(data => {
            localStorage.setItem('blogspotUser', data)
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <Modal
            ariaHideApp={false}
            isOpen={props.prop}
            style={customStyles}
            contentLabel="Example Modal">
            <div className='modal-body'>

                <span className="material-icons closeModal" onClick={e => props.close(false)}>
                    cancel
                </span>
                <center><img src="/image/Group 3.png" className="index_logo" alt=""/></center>
                <div className='third-party-login-icon'>
                    {/* <div className='switch-button'>
                        <div
                            className='btn btn-default'
                            style={{
                            "opacity": switchButton
                                ? 1
                                : 0.5,
                            "borderBottom": switchButton
                                ? "1px solid #ffffff"
                                : "none"
                        }}
                            onClick={e => setSwitchButton(true)}>Login</div>
                        <div
                            className='btn btn-default'
                            style={{
                            "opacity": !switchButton
                                ? 1
                                : 0.5,
                            "borderBottom": !switchButton
                                ? "1px solid #ffffff"
                                : "none"
                        }}
                            onClick={e => setSwitchButton(false)}>Register</div>
                    </div> */}
                    <center>
                        <span>
                            <img src="/image/Group 2.png" onClick={handleClickGoogle}/>
                        </span>
                        <span>
                            <img src="/image/Group 1.png" onClick={handleClickMicrosoft}/>
                        </span>
                    </center>
                </div>

            </div>

        </Modal>
    )
}

export default Modal_module;