import {getAuth,signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import createUser from './createUser';
const provider = new GoogleAuthProvider();
const auth = getAuth();
const Google = () => {
    return new Promise(async(resolve, reject) => {
        signInWithPopup(auth, provider).then(async(result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            await createUser(user.uid, user.displayName, user.email).then((result) => {
               resolve(result)
            })
            .catch((error) =>{
                reject(error)
            })

        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's accoaunt used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

    })
}

export default Google