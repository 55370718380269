const Config = {
    apiKey: "AIzaSyBeE3S52FzystphR_7EC6r3qBB0y-gEyHQ",
    authDomain: "blog-7b42d.firebaseapp.com",
    projectId: "blog-7b42d",
    storageBucket: "blog-7b42d.appspot.com",
    messagingSenderId: "951598400147",
    appId: "1:951598400147:web:7fb31c29440d8999e14366"
};
export default Config

