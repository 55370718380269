import axios from 'axios';

const graphUrl = (token) => {
    return new Promise(async(resolve, reject) => {
        axios({
            method: 'get',
            url: 'https://graph.microsoft.com/v1.0/me',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(result => {
        
         resolve(result.data);

        }).catch(error => reject(error.message));
    })

}

export default graphUrl