import React from 'react';
import './Loading.css'

const Loading = () => {

    return (

        <div className='contentloding'>
           <center><h1 ></h1></center> 
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </div>
    );
};

export default Loading;
