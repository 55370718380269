import React from 'react';
import './Loading.css'

const Loading = () => {

    return (

        <div className='container-fluid'>
        <div className='row'>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
            <div className="col-md-4 col-6'">
               <div className='skeleton'></div>
            </div>
        </div>
    </div>
    );
};

export default Loading;
