import React from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react';

import './editor.css'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import {initializeApp} from 'firebase/app';
import Config from '../Firebase/Config'
import {
    getDatabase,
    ref,
    set,
    update,
    push,
    child,
    remove,
    get
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());
Quill.register('modules/imageResize', ImageResize);
var modules = {
    toolbar: [
        [
            {
                size: ["small", false, "large", "huge"]
            }
        ],
        [
            "bold", "italic", "underline", "strike", "blockquote"
        ],
        [
            {
                list: "ordered"
            }, {
                list: "bullet"
            }
        ],
        [
            "link", "image"
        ],
        [
            {
                list: "ordered"
            }, {
                list: "bullet"
            }, {
                indent: "-1"
            }, {
                indent: "+1"
            }, {
                align: []
            }
        ],
        [
            {
                "color": [
                    "#000000",
                    "#e60000",
                    "#ff9900",
                    "#ffff00",
                    "#008a00",
                    "#0066cc",
                    "#9933ff",
                    "#ffffff",
                    "#facccc",
                    "#ffebcc",
                    "#ffffcc",
                    "#cce8cc",
                    "#cce0f5",
                    "#ebd6ff",
                    "#bbbbbb",
                    "#f06666",
                    "#ffc266",
                    "#ffff66",
                    "#66b966",
                    "#66a3e0",
                    "#c285ff",
                    "#888888",
                    "#a10000",
                    "#b26b00",
                    "#b2b200",
                    "#006100",
                    "#0047b2",
                    "#6b24b2",
                    "#444444",
                    "#5c0000",
                    "#663d00",
                    "#666600",
                    "#003700",
                    "#002966",
                    "#3d1466",
                    'custom-color'
                ]
            }
        ]
    ],
    imageResize: {
        parchment: Quill.import ('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};
var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size"
];
const Editor = () => {
    const [content,
        setContent] = React.useState('')

    const handleProcedureContentChange = (e) => {
        setContent(e);
    };
    React.useEffect(() => {
        if (!localStorage.getItem('blogspotUser')) 
            window.location.href = '/'
    })
    function breakStringIntoArrays(str, arrayLength) {
        let result = [];
        for (let i = 0; i < str.length; i += arrayLength) {
            result.push(str.slice(i, i + arrayLength));
        }
        return result;
    }
    const submit = e => {

        if (document.getElementById('Title').value.toString().trim().length === 0) 
            return 0
        let time = new Date().toLocaleString()
        var blogStrong = breakStringIntoArrays(content, 1000000)
        console.log(blogStrong[0].length)
        get(child(dbRef, localStorage.getItem('blogspotUser') + '/Profile/name')).then((snapshot) => {
            const puskKey = push(child(ref(db), localStorage.getItem('blogspotUser') + '/Blog/')).key;
            set(ref(db, localStorage.getItem('blogspotUser') + '/Blog/' + puskKey), {
                Title: document
                    .getElementById('Title')
                    .value
                    .toString()
                    .trim(),
                id: puskKey,
                authorid: localStorage.getItem('blogspotUser'),
                visibility : !document.getElementById('visibility').checked,
                Blog: {
                    content: blogStrong,
                    Author: snapshot.val(),
                    Time: time
                }
            });
            set(ref(db, '/BlogList/' + puskKey), {
                Title: document
                    .getElementById('Title')
                    .value
                    .toString()
                    .trim(),
                id: puskKey,
                visibility : !document.getElementById('visibility').checked,
                Author: snapshot.val(),
                Time: time,
                authorid: localStorage.getItem('blogspotUser')
            });
            setContent('')
            document
                .getElementById('Title')
                .value = ''
            document
                .getElementById('reactQuill')
                .value = ''
        }).catch((error) => {
            console.log(error.message)
        });

    }
    return (
        <div >

            <h1 className='Pageheading'>Write</h1>
            <div className='topMenu'>
                <button className='btn btn-primary' onClick={submit}>Publish</button>

            </div>
            <div className='container-fluid editorCon'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <h1></h1>
                            <input type='text' className='form-control' id="Title" placeholder='Title *'/>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div
                            style={{
                           
                        }}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={content}
                                id='reactQuill'
                                placeholder="write your thoughts ...."
                                onChange={e => setContent(e)}
                                style={{
                                height: "55dvh",
                                width: "73dvw"
                            }}></ReactQuill>
                        </div>
                        <br/><br/>
                    </div>
                    <div className='col-md-4 col-6'>
                       
                        <form className="public-private-toggle">
                            Public Blog
                            <span className="ios-toggle">
                                <input type="checkbox" id="visibility"/>
                                <label for="visibility"></label>
                            </span>
                            Private Blog
                        </form>
                    </div>
                    {/* <div className='col-md-4 col-6'>
                        <p className='tootleP'>Download Option</p>
                        <form className="public-private-toggle">
                            Yes
                            <span className="ios-toggle">
                                <input type="checkbox" id="downaload"/>
                                <label for="downaload"></label>
                            </span>
                            No
                        </form>
                    </div>
                    <div className='col-md-4 col-6'>
                      <p className='tootleP'>Show View Count</p>
                        <form className="public-private-toggle">
                            Yes
                            <span className="ios-toggle">
                                <input type="checkbox" id="count"/>
                                <label for="count"></label>
                            </span>
                            No
                        </form>
                    </div> */}
                </div>
            </div>

        </div>
    );
};

export default Editor;
