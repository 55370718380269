import Config from '../Firebase/Config'
import {initializeApp} from 'firebase/app';
import {
    get,update,child,getDatabase,ref
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());

const createFolder = (userId,name,email) => {
    return new Promise(async(resolve, reject) => {
        get(child(dbRef, userId + '/Profile')).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(userId)
            } else {
                    update(ref(db, userId + '/Profile'), {
                        name: name,
                        email: email,
                        id : userId
                    });   
                    resolve(userId)
            }
        }).catch((error) => {
            reject(error.message)
        });
    })

}

export default createFolder