import React from 'react';
import './Search.css'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import {initializeApp} from 'firebase/app';
import Config from '../Firebase/Config'
import parse from 'html-react-parser';

import Loading from '../Loading/Blog/Loading';
import {
    getDatabase,
    ref,
    get,
    child,onValue,orderByValue,query,
    remove
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());
const Name = () => {
  // var [previewdata] = useListVals(ref(db, '/BlogList'));
    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    // if(!loading){
    
    // }
   // previewdata = getUniqueListBy(previewdata, 'id')
    const [bloglist,setBloglist] = React.useState()
    const getBlogList = async e=>{
        let data = []
       await  get(child(dbRef, '/BlogList/')).then((snapshot) => {
             snapshot.forEach((item) => {
                if(item.val().authorid === localStorage.getItem('blogspotUser'))
                   data.push(item.val())
                else if(item.val().visibility) data.push(item.val())
            })
              //setBloglist(snapshot.child())
           })
        setBloglist([...data])
        setTimeout(() => {
            document.getElementById('Searchcontent-loading').style.display = 'none';
            document.getElementById('sechocontent-main').style.display = 'block';
         }, 10);
    }
    const deleteBlog = e => {
        remove(ref(db, localStorage.getItem('blogspotUser')+'/Blog/' + e))
        remove(ref(db, '/BlogList/' + e))
        getBlogList()
    }
    React.useEffect( ()=>{
        getBlogList()
           
    },[])
 

    const searchSubmit = async e =>{
     if(document.getElementById('searchtopic').value.trim() === 0){
        getBlogList()
        return 0
     }
     let data = []
     await  get(child(dbRef, '/BlogList/')).then((snapshot) => {
        snapshot.forEach((item) => {
           if((item.val().Title.toLowerCase().includes(document.getElementById('searchtopic').value.trim().toLowerCase()))
              ||  (item.val().Author.toLowerCase().includes(document.getElementById('searchtopic').value.trim().toLowerCase()))
           )
           data.push(item.val())
       })
         //setBloglist(snapshot.child())
      })
      setBloglist([...data])

    }

    return (
        <div >
          <div className='container-fluid searchSection' >
            <div className='row'>
                <div className='col-12'>
                    <center>
                    <div className='form-group'>
                        <input type='text' id="searchtopic" placeholder='Search Topic / Author'/>
                        <button className='btn btn-primary' onClick={searchSubmit}>Search</button>
                    </div>
                    </center>
                   
                </div>
                
            </div>
          </div>
          <div id="Searchcontent-loading" className='animate__animated animate__fadeIn'>
                <Loading/>
            </div>
            <div className='container-fluid myblogsection animate__animated animate__fadeIn' id="sechocontent-main">
                <div className='row'>
                    {bloglist && bloglist.length === 0
                        ? <span className='noBlog'>
                                <center>
                                    <img src="/image/undraw_empty_street_re_atjq.svg"/>
                                    <p>Blog Not Found</p>
                                </center>

                            </span>

                        : bloglist && bloglist.map((data, index) => {

                            return (
                                <div className='col-md-4 col-12'>

                                    <div className='myblog-box text-center'>
                                        {localStorage.getItem('blogspotUser') && localStorage.getItem('blogspotUser') === data.authorid?
                                         <span className='material-icons deleteIcon' onClick={e => deleteBlog(data.id)}>delete</span> :
                                         <span></span>

                                        }
                                   
                                        <span className="material-icons writeIcon">
                                            history_edu
                                        </span>
                                        <p
                                            onClick={e => {
                                            window.location.href = data.authorid+ '/content/' + data.id + '/' + data.Title
                                        }}>{data.Title}</p>
                                        <label>{data.Author} &nbsp;&nbsp;&nbsp; {data.Time}</label>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    );
};

export default Name;
