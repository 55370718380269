import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

const auth = getAuth();
const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'http://localhost.com:3000?email=1234',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'example.page.link'
};
const Email = (e, email, password) => {
    return new Promise(async(resolve, reject) => {
      console.log( isSignInWithEmailLink(auth, window.location.href))
        // if (isSignInWithEmailLink(auth, window.location.href)) {
        //     // Additional state parameters can also be passed via URL.
        //     // This can be used to continue the user's intended action before triggering
        //     // the sign-in operation.
        //     // Get the email if available. This should be available if the user completes
        //     // the flow on the same device where they started it.
           let email = localStorage.getItem('emailForSignIn');
            if (!email) {
              // User opened the link on a different device. To prevent session fixation
              // attacks, ask the user to provide the associated email again. For example:
              email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, actionCodeSettings)
              .then((result) => {
                console.log(result)
                // Clear email from storage.
               localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
              })
              .catch((error) => {
                console.log(error);
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
              });
           // }
    })
}

export default Email