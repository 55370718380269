import React from 'react'
import Editor from '../Editor/Editor';
import Myblog from '../Myblog/Myblog';
import Notfound from '../Notfound/Notfound';
import Content from '../Content/Content';
import Main from '../Main/Main';
import Name from '../Name/Name';
import Signout from '../Provider/signout'
import Search from '../Search/Search';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './Dashboard.css'
import Modal_module from '../Main/Modal_module'
const Dashboard = () => {
    const [modalIsOpenFolder,
        setIsOpenFolder] = React.useState(false);
    const [userId,
        setuserid] = React.useState(localStorage.getItem('blogspotUser'))
    const logout = async e => {
        await Signout().then(() => {
            window
                .location
                .reload()
        }).catch(err => console.log(err))
    }
    const [opnMenu,setopnMenu] = React.useState(false)
    const menu = e =>{ 
      console.log(opnMenu)
         if(opnMenu){
             document.getElementById('firstSection').style.left="-100%"
             setopnMenu(false)}
         
    }
    return (
        <Router>
        <div className='dashboard'>
            <Modal_module prop={modalIsOpenFolder} close={e => setIsOpenFolder(false)}/>
            <div className='first-section' id="firstSection">

                <div className='icon-section'>
                    <span className='material-icons' id="closeMenu" onClick={e=>{
                        //setopnMenu(true)
                        document.getElementById('firstSection').style.left='-100%'
                 }}>highlight_off</span>
                    <a href="/">
                        <img src="/image/Group 3.png"/>
                    </a>
                    <a>
                        <p><Name/></p>
                    </a>
                </div>
                <div className='menu-section'>
                    {!userId
                        ? <div>
                                <a href="/Search" onClick={e => menu()}>
                                    <li >
                                        <span class="material-icons">
                                            manage_search
                                        </span>Search</li>
                                </a>
                                <a onClick={e => {
                                    menu()
                                    setIsOpenFolder(true)}}>
                                    <li >
                                        <span class="material-icons">
                                            login
                                        </span>Login</li>
                                </a>
                            </div>
                        : <div>
                            <a href="/Blog" onClick={e => menu(true)}>
                                <li >
                                    <span class="material-icons">
                                        history_edu
                                    </span>My Blog</li>
                            </a>
                            <a href="/Write" onClick={e => menu(true)}>
                                <li >
                                    <span class="material-icons">
                                        add_circle_outline
                                    </span>Create</li>
                            </a>
                            <a href="/Search" onClick={e => menu(true)}>
                                <li >
                                    <span class="material-icons">
                                        manage_search
                                    </span>Search</li>
                            </a>
                            <a href="/" onClick={e => menu(true)}>
                                <li >
                                    <span class="material-icons">
                                        account_circle
                                    </span>Profile</li>
                            </a>
                            <a onClick={e => logout()}>
                                <li >
                                    <span class="material-icons">
                                        logout
                                    </span>Logout</li>
                            </a>
                        </div>
}
                    <span className='ownername'>Created By Priyonuj Dey</span>
                </div>
            </div>
            <div className='second-section'>
                <span class="material-icons" id="openMenu" onClick={e=>{
                    setopnMenu(true);
                    document.getElementById('firstSection').style.left='0%'
                }}>
                    menu_open
                </span>
            
                    <Routes>
                        <Route exact path='/' Component={Main}/>
                    </Routes>
                    <Routes>
                        <Route exact path='/Search' Component={Search}/>
                    </Routes>
                    <Routes>
                        <Route exact path='/Blog' Component={Myblog}/>
                    </Routes>
                    <Routes>
                        <Route exact path='/Write' Component={Editor}/>
                    </Routes>
                    <Routes>
                        <Route exact path='/:userid/content/:blogid/:title' Component={Content}/>
                    </Routes>
              
            </div>

        </div>  </Router>
    );
};

export default Dashboard;
