import {getAuth, signOut} from "firebase/auth";
const auth = getAuth();

const Signout = () => {
    return new Promise(async(resolve, reject) => {
        signOut(auth).then(() => {
            localStorage.removeItem('blogspotUser')
            resolve('')
        }).catch((error) => {
              reject(error.message)
            // An error happened.
        });

    })
}

export default Signout