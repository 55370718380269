import React from 'react';
import './Myblog.css'
import {useObjectVal, useListVals} from 'react-firebase-hooks/database';
import {initializeApp} from 'firebase/app';
import Config from '../Firebase/Config'
import Loading from '../Loading/Blog/Loading';
import 'quill/dist/quill.snow.css'
import {
    getDatabase,
    ref,
    set,
    update,
    push,
    child,
    remove
} from "firebase/database";
const app = initializeApp(Config);
const db = getDatabase(app);
const dbRef = ref(getDatabase());

const Myblog = () => {
    var [previewdata,
        loading] = useListVals(ref(db, localStorage.getItem('blogspotUser') + '/Blog/'));
    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    if (!loading) {
        setTimeout(() => {
            document
                .getElementById('myblog-loading')
                .style
                .display = 'none';
            document
                .getElementById('myblogSection')
                .style
                .display = 'block';
        }, 10);
    }
    previewdata = getUniqueListBy(previewdata, 'id')
    const deleteBlog = e => {
        remove(ref(db, localStorage.getItem('blogspotUser') + '/Blog/' + e))
        remove(ref(db, '/BlogList/' + e))
    }
    React.useEffect(() => {
        if (!localStorage.getItem('blogspotUser')) 
            window.location.href = '/'
    })
    const visibilityChanged = (val,id) =>{
        update(ref(db, localStorage.getItem('blogspotUser') + '/Blog/' + id), {
            visibility : !val,
        });
        update(ref(db,  'BlogList/' + id), {
            visibility : !val,
        });
    }
    return (
        <div >
            <h1 className='Pageheading'>My-Blog</h1>
            <div className=' animate__animated animate__fadeIn' id="myblog-loading">
                <Loading/>
            </div>
            <div
                className='container-fluid myblogsection animate__animated animate__fadeIn'
                id="myblogSection">
                <div className='row'>
                    {previewdata.length === 0
                        ? <span className='noBlog'>
                                <center>
                                    <img src="/image/undraw_empty_street_re_atjq.svg"/>
                                    <p>Blog Not Found</p>
                                </center>

                            </span>

                        : previewdata.map((data, index) => {
                            return (
                                <div className='col-md-4 col-12'>

                                    <div className='myblog-box text-center'>
                                        <div className='visivility'>
                                            <form className="public-private-toggle">
                                                Public
                                                <span className="ios-toggle1">
                                                    <input type="checkbox" id={"visibility" + data.id} defaultChecked={!data.visibility}
                                                    onChange={e=>visibilityChanged(e.target.checked,data.id)}
                                                    />
                                                    <label for={"visibility" + data.id}></label>
                                                </span>
                                                Private
                                            </form>
                                        </div>
                                        <span className='material-icons deleteIcon' onClick={e => deleteBlog(data.id)}>delete</span>
                                        
                                        <br/><span className="material-icons writeIcon">
                                            history_edu
                                        </span>
                                        <p
                                            onClick={e => {
                                            window.location.href = localStorage.getItem('blogspotUser') + '/content/' + data.id + '/' + data.Title
                                        }}>{data.Title}</p>
                                        <label>{data.Blog.Time}</label>

                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>

        </div>
    );
};

export default Myblog;
