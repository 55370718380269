import React from 'react';

import './App.css';

import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from  "./Dashboard/Dashboard"

function App() {
    return (
        <div>
           <Dashboard/> 
        </div>
    );
}
function Loader() {
    return (
        <div
            className="loader1 animate__animated animate__fadeIn"
            style={{
            'paddingTop': '5%'
        }}>
            <center><img src="/image/loading.gif"/></center>
        </div>
    )
}
export default App;
